import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  gap: min(7vw, 20rem);

  @media (max-width: ${breakpoints.tablet768}px)
  {
    flex-direction: column;
  }
`;

export const contentContainer = css`
  width: 50%;
  min-width: 25rem;
  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: .5rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 120%;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  
  @media (max-width: ${breakpoints.tablet1024}px)
  {
    h3 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 2.5rem;
    }
  }
  @media (max-width: ${breakpoints.tablet768}px)
  {
    width: 100%;
  }
`;

export const imageContainer = css`
  width: 50%;
  
  p{
    margin-top: .8rem;
  }
  @media (max-width: ${breakpoints.tablet768}px)
  {
    width: 100%;
  }
`;

export const topImageWrapper = css`
  display: flex;
  height: 40rem;
`;

export const bottomImageWrapper = css`
  display: flex;
  height: 70rem;
`;

export const images = css`
  height: 100%;
`;

export const topRightImageDescription = css`
  margin-bottom: 3.2rem;
`;

export const richTextWrapper = css`
  p {
    min-height: 6rem;
  }

  @media (max-width: ${breakpoints.tablet1024}px)
  {
    p {
      min-height: 3rem;
    }
  }
`;
