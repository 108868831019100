import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenTextWithImagesVariant1 } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent, useEffect, useRef, useState } from "react";

import * as styles from "./TextWithImagesVariant1.style";

const TextWithImagesVariant1: FunctionComponent<IGenTextWithImagesVariant1> = ({
  image1,
  image2,
  richtextDescription,
  title
}) =>
{
  const textBoxWrapperRef = useRef<HTMLDivElement>(null);
  const [textBoxWrapperHeight, setTextBoxWrapperHeight] = useState(0);

  useEffect(() =>
  {
    const handleResize = (): void =>
    {
      if(textBoxWrapperRef.current)
      {
        setTextBoxWrapperHeight(textBoxWrapperRef.current.offsetHeight);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () =>
    {
      window.removeEventListener("resize", handleResize);
    };
  }, [textBoxWrapperRef]);

  const image2HeightInRem = 67.1;
  const textBoxWrapperHeightInRem = textBoxWrapperHeight / 10;
  const contentWrapperHeight = `${image2HeightInRem + textBoxWrapperHeightInRem}rem`;

  return (
    <ContentWrapper css={styles.wrapper(contentWrapperHeight)}>
      <div css={styles.textBoxAndImageWrapper} ref={textBoxWrapperRef}>
        <UnpicImage
          css={styles.image2("67.1rem")}
          src={image2?.src || ""}
          alt={image2?.title || ""}
          layout="fullWidth"
          background="auto"
        />
        {title && <h2>{title}</h2>}
        {richtextDescription && (
          <div>
            <RichTextRenderer node={richtextDescription.json}/>
          </div>
        )}
      </div>
      <div css={styles.imageWrapper}>
        <UnpicImage
          src={image1?.src || ""}
          alt={image1?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
    </ContentWrapper>
  );
};

export default TextWithImagesVariant1;
