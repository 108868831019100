import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const contentWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const image = css`
  width: 100%;
  height: 100%;
`;

export const image1Wrapper = css`
  display: flex;
  height: 83rem;

  @media screen and (max-width: ${breakpoints.tablet768}px) {
    height: 70rem;
  }
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    height: 70vh;
  }
`;

export const image2And3InnerWrapper = css`
  display: flex;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 50%;
  }
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    width: 100%;
  }
`;

export const image2And3OuterWrapper = css`
  display: flex;
  gap: 2rem;
  height: 54rem;

  @media screen and (max-width: ${breakpoints.tablet768}px) {
    height: 45rem;
  }
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    flex-direction: column;
    height: auto;
  }
`;

export const image2Wrapper = css`
  width: 60%;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 55%;
  }

  @media screen and (max-width: ${breakpoints.mobile500}px) {
    height: 60vh;
  }
`;

export const image3Wrapper = css`
  width: 40%;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 45%;
  }

  @media screen and (max-width: ${breakpoints.mobile500}px) {
    height: 50vh;
  }
`;
