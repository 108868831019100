import { colors } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

const border = `1px solid ${colors.gray["200"]}`;

export const wrapper = css`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-top: ${border};
  border-left: ${border};
`;

export const logoWrapper = css`
  width: 25%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${border};
  border-bottom: ${border};
  @media screen and (max-width: ${breakpoints.desktop1366}px) {
    width: 33%;
  }
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    width: 50%;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    height: 16rem;
  }
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    width: 100%;
  }
`;

export const logo = (scaleInPercentage: number) => css`
  max-width: 80%;
  height: 4.3rem;
  transform: scale(calc(${scaleInPercentage}/100));
  object-fit: contain;
`;
