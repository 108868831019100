import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  margin-top: 6rem;
  
  h1 {
    font-weight: 500;
    font-size: 6.5rem;
  }
  
  @media (max-width: ${breakpoints.tablet1024}px)
  {
    h1 {
      font-size: 4rem;
    }
  }
  
  @media (max-width: ${breakpoints.mobile500}px)
  {
    h1 {
      font-size: 3rem;
    }
  }
`;

export const richTextWrapper = css`
  width: 40%;
  margin-top: 2rem;

  @media (max-width: ${breakpoints.tablet1024}px)
  {
    width: 100%;
  }
`;
