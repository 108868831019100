import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  h2 {
    font-weight: 500;
    font-size: 6rem;
    margin-bottom: 6rem;
  }

  @media (max-width: ${breakpoints.tablet1024}px)
  {
    h2 {
      font-size: 4rem;
    }
  }

  @media (max-width: ${breakpoints.mobile500}px)
  {
    h2 {
      font-size: 3rem;
      line-height: 130%;
    }
  }
`;

export const gridWrapper = css`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(6,.5fr);
  
  @media (max-width: ${breakpoints.desktop1366}px)
  {
    grid-template-columns: repeat(4,.5fr);
  }

  @media (max-width: ${breakpoints.tablet768}px)
  {
    grid-template-columns: repeat(2,.5fr);
  }
`;
