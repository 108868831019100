import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenPageTitle } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent } from "react";

import * as styles from "./PageTitle.style";

const PageTitle: FunctionComponent<IGenPageTitle> = ({ ptDescription, title }) => (
  <ContentWrapper css={styles.wrapper}>
    <h1>{title}</h1>
    {ptDescription && (
      <div css={styles.richTextWrapper}>
        <RichTextRenderer node={ptDescription.json}/>
      </div>
    )}
  </ContentWrapper>
);

export default PageTitle;
