import { css } from "@emotion/react";

export const wrapper = css`
  width: 100%;
`;

export const componentWrapper = css`
  margin: clamp(7rem, 11vw, 14rem) 0;
  width: 100%;
  :first-of-type {
    margin-top: 0;
  }
`;
