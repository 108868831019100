import SlugPage from "@/components/pages/slug/Slug.page";
import { type PreviewType } from "@/pages/api/preview";
import { getAllPages } from "@/services/content/getAllPages";
import { getCommonProps, type GetCommonPropsResult } from "@/services/content/getCommonProps";
import { getSlugPage, type GetSlugPageResult } from "@/services/content/getSlugPage";

import { type GetStaticPaths, type GetStaticPathsResult, type GetStaticProps } from "next";
import React, { type FunctionComponent } from "react";

import { type ParsedUrlQuery } from "querystring";

interface Params extends ParsedUrlQuery
{
  slug: string | undefined;
}

export const getStaticPaths: GetStaticPaths<Params> = async ({ defaultLocale = "de" }) =>
{
  const commonProps = await getCommonProps({ locale: defaultLocale, previewMode: "published" });
  const allPages = await getAllPages("published");

  const paths: GetStaticPathsResult<Params>["paths"] = allPages
    .filter(page => page.slug !== "404")
    .filter(page => page.slug !== commonProps.header.rootPage?.slug)
    .map(page => ({
      params: {
        slug: page.slug!,
      }
    }));

  return {
    fallback: true,
    paths
  };
};

export type GetSlugPagePropsResult = GetSlugPageResult & GetCommonPropsResult;

export const getStaticProps: GetStaticProps<GetSlugPagePropsResult, Params> = async ({ draftMode, locale = "de", params }) =>
{
  // console.log(`getStaticProps for ${params?.slug}. Draft mode is ${draftMode ? "enabled" : "disabled"}`);

  const previewMode: PreviewType = draftMode ? "draft" : "published";
  const slug = params?.slug;
  const commonProps = await getCommonProps({ locale, previewMode });
  const rootPageSlug = commonProps.header.rootPage?.slug;

  if(slug === rootPageSlug)
  {
    return {
      redirect: {
        destination: "/",
        permanent: true,
      },
      revalidate: 1,
    };
  }

  const slugPageProps = await getSlugPage({
    locale,
    previewMode,
    rootPageSlug,
    slug
  });

  if(slugPageProps.resultType === "notFound")
  {
    return {
      notFound: true,
      revalidate: false,
    };
  }

  return {
    props: {
      ...commonProps,
      ...slugPageProps,
    },
    revalidate: 1,
  };
};

const NextjsPage: FunctionComponent<GetSlugPagePropsResult> = props =>
{
  return <SlugPage {...props}/>;
};

export default NextjsPage;
