import BottomBoxes from "@/components/buildingBlocks/heroVariant1/bottomBoxes/BottomBoxes";
import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenLinkTypExternal, type IGenHeroVariant1, type IGenAsset } from "@/services/graphql/__generated/sdk";
import { getRandomArrayValue } from "@/utils/getRandomArrayValue";
import { isFileLink } from "@/utils/isFileUrl";
import { type Nullable } from "@/utils/types";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import { Button } from "@mui/material";
import download from "downloadjs";
import Link from "next/link";
import React, { type FunctionComponent, useEffect, useState } from "react";

import * as styles from "./HeroVariant1.style";

interface IProps
{
  readonly isHeaderFloating: boolean;
}

const HeroVariant1: FunctionComponent<IGenHeroVariant1 & IProps> = ({
  bottomBoxes,
  cta,
  images,
  isHeaderFloating,
  preTitle,
  subTitle,
  title
}) =>
{
  const onExternalLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, url: string): void =>
  {
    if(isFileLink(url))
    {
      e.preventDefault();
      download(url);
    }
  };

  const [randomImage, setRandomImage] = useState<Nullable<IGenAsset>>(null);

  useEffect(() =>
  {
    setRandomImage(getRandomArrayValue<IGenAsset>(images?.filter(Boolean) || []));
  }, [images]);

  // needs to be done with state and effect because of SSR and server/client mismatch
  // const randomImage = getRandomArrayValue<IGenAsset>(images?.filter(Boolean) || []);

  return (
    <div css={styles.wrapper(isHeaderFloating ? 0 : 8)}>
      {randomImage && (
        <UnpicImage
          css={styles.image}
          src={randomImage?.src || ""}
          alt={randomImage?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      )}
      <ContentWrapper css={styles.contentWrapper}>
        <div css={styles.content}>
          {preTitle && <h2 css={styles.preTitle}>{preTitle}</h2>}
          {title && <h1 css={styles.title}>{title}</h1>}
          {subTitle && (
            <div css={styles.subTitle}>
              <RichTextRenderer node={subTitle.json}/>
            </div>
          )}
          {cta && (
            <div css={styles.ctaWrapper}>
              {cta.link?.__typename === "LinkTypExternal" && (
                <Button
                  href={cta.link.url || ""}
                  target="_blank"
                  onClick={e => onExternalLinkClick(e, (cta.link as IGenLinkTypExternal)?.url || "")}
                  component="a"
                  variant="contained">
                  {cta.title}
                </Button>
              )}
              {cta.link?.__typename === "LinkTypInternal" && (
                <Button
                  href={cta.link.page?.[0]?.slug || ""}
                  component={Link}
                  variant="contained">
                  {cta.title}
                </Button>
              )}
            </div>
          )}
        </div>
      </ContentWrapper>
      <ContentWrapper css={styles.bottomBoxesContentWrapper}>
        <BottomBoxes boxes={bottomBoxes}/>
      </ContentWrapper>
    </div>
  );
};

export default HeroVariant1;
