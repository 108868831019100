import UnpicImage from "@/components/common/unpicImage/UnpicImage";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type ComponentProps, type FunctionComponent } from "react";

import * as styles from "./GridItem.style";

// Any because of maybe types which come from the generated codes. If we don't use any here and codegen changes the project will fail to run in a future update
interface IProps extends ComponentProps<"div">
{
  readonly ctaButtonLinkTarget: any | undefined;
  readonly ctaButtonText: any | undefined;
  readonly image?: any;
  readonly richText: any;
  readonly title: any | undefined;
}

const GridItem: FunctionComponent<IProps> = ({
  ctaButtonLinkTarget,
  ctaButtonText,
  image,
  richText,
  title,
  ...props
}) => (
  <div css={styles.gridItemWrapper} {...props}>
    {image && (
      <div css={styles.imageWrapper}>
        <UnpicImage
          layout="fullWidth"
          src={image?.src || ""}
          alt={image?.title || ""}
          background="auto"
        />
      </div>
    )}
    <div css={styles.contentWrapper}>
      <h3>{title}</h3>
      <RichTextRenderer node={richText}/>
      {ctaButtonText && ctaButtonLinkTarget && (
        <a
          css={styles.websiteButton}
          href={ctaButtonLinkTarget}
          target="_blank"
          rel="noreferrer">{ctaButtonText}&nbsp;&#10132;
        </a>
      )}
    </div>
  </div>
);

export default GridItem;
