/* eslint-disable max-lines */
import { headerHeightInRem } from "@/components/common/header/Header.style";
import { inter } from "@/pages/_app";

import { css } from "@emotion/react";

export const wrapper = css`
  position: relative;
  transform: translateY(0px);
  display: flex;
  flex-direction: row;
  margin-bottom: -14rem;

  @media screen and (max-width: 1300px) {
    margin-bottom: -13rem;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: -12rem;
  }
  @media screen and (max-width: 1100px) {
    margin-bottom: -11rem;
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: -8rem;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 130px;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 375px) {
    margin-bottom: 190px;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  .property {
    position: relative;
  }
`;

export const locationCardSmall = css`
  padding: 20px;
  background: #f1f5f9;
  word-break: break-word;
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }

  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  button {
    font-size: 12px;
    line-height: 16px;
    color: #0f172a;
    padding: 6px 12px;
    background-color: #fff;
    outline: 0;
    border: 0;
    border-radius: 6px;
    margin-top: 16px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  }
  pre {
    font-family: ${inter.style.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: #475569;
    white-space: break-spaces;
  }
`;
export const locationCardSmallSelected = css`
  ${locationCardSmall}
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
`;

export const locationCardSmallTitle = css`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #0f172a;
`;
export const locationCardSmallSubtitle = css`
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
`;

export const locationsWrapper = css`
  display: flex;
  gap: 18px;
  flex-direction: column;
  padding: 0 32px 32px 32px;
  width: 500px;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 32px;
    height: 800px;
  }
  .logo {
    width: 80%;
    img {
      &:last-child {
        margin-bottom: 20px;
      }
      object-fit: contain;
      max-width: 40%;
    }
  }
`;

export const mapWrapper = css`
  width: 100%;
  z-index: 1;
`;

export const mapHeightSingleLocation = css`
  height: 70vh;
  min-height: 50rem;
`;

export const mapHeightMultiLocations = css`
  height: calc(100vh - ${headerHeightInRem}rem);
`;

export const resetMapButtonWrapper = css`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
  button {
    font-size: 1.6rem;
  }
`;

export const locationCard = css`
  padding: 24px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 1);
  min-width: 300px;
  max-width: 400px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 14px 54px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  translate: 0 -50%;
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
    width: 80%;
    img {
      &:last-child {
        margin-bottom: 50px;
      }
      object-fit: contain;
      max-height: 150px;
      max-width: 100%;
    }
  }
  pre {
    font-family: ${inter.style.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: #475569;
    white-space: break-spaces;
  }
  @media screen and (max-width: 1024px) {
    left: 30px;
    max-width: min(400px, 42vw);
  }
  @media screen and (max-width: 768px) {
    width: 70%;
    max-width: unset;
    top: 87%;
    height: max-content;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    pre {
      text-align: center;
    }
  }
  @media screen and (max-width: 450px) {
    width: 80%;
    pre {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 92%;
    top: 90%;
    height: max-content;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const breakWord = css`
  word-break: break-word;
`;
