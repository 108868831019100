import {
  type IGenMapLocation,
  type Maybe,
} from "@/services/graphql/__generated/sdk";

import React from "react";

import * as styles from "./MapComponent.style";

export const SelectedLocationCard: React.FC<{
  readonly selectedLocation: Maybe<IGenMapLocation> | undefined;
}> = ({ selectedLocation }) => 
{
  return (
    <div css={styles.locationCard}>
      <div className="logo">
        {selectedLocation?.logo?.map(
          (logo, index) =>
            logo?.src && <img key={index} src={logo?.src} alt="logo"/>
        )}
      </div>
      {selectedLocation?.title && (
        <pre className="title" style={{ fontWeight: 600 }}>{selectedLocation?.title}</pre>
      )}
      {selectedLocation?.subtitle && (
        <pre className="subtitle">{selectedLocation?.subtitle}</pre>
      )}
      {selectedLocation?.address && <pre>{selectedLocation?.address}</pre>}
      {selectedLocation?.phone && <pre>Fon {selectedLocation?.phone}</pre>}
      {selectedLocation?.email && <pre style={{ }}>{selectedLocation?.email}</pre>}
      {selectedLocation?.website && <pre>{selectedLocation?.website}</pre>}
    </div>
  );
};
