import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenFullscreenImage } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./FullscreenImage.style";

const FullscreenImage: FunctionComponent<IGenFullscreenImage> = ({ image }) => (
  <ContentWrapper css={styles.wrapper}>
    <UnpicImage
      css={styles.image}
      src={image?.src || ""}
      alt={image?.title || ""}
      layout="fullWidth"
      background="auto"
      cdn="bunny"
    />
  </ContentWrapper>
);

export default FullscreenImage;
