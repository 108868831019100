import {
  type IGenMapLocation,
  type Maybe,
} from "@/services/graphql/__generated/sdk";

import React, { type Dispatch, type SetStateAction } from "react";

import * as styles from "./MapComponent.style";

interface IProps
{
  readonly mapLocations: Maybe<Maybe<IGenMapLocation>[]> | undefined;
  readonly multiLocationsMap: boolean;
  readonly selectedLocation: any;
  readonly setSelectedLocation: Dispatch<SetStateAction<Maybe<IGenMapLocation> | undefined>>;
}

export const LocationsList = React.forwardRef<HTMLDivElement, IProps>(({
  mapLocations,
  multiLocationsMap,
  selectedLocation,
  setSelectedLocation
}, ref) => (
  <div css={[styles.locationsWrapper, multiLocationsMap ? styles.mapHeightMultiLocations : styles.mapHeightSingleLocation]} ref={ref}>
    {mapLocations && mapLocations.filter(Boolean).map((location, index) =>
      location?.id && (
        <div
          id={location?.id}
          key={index}
          css={location?.id !== selectedLocation?.id ? styles.locationCardSmall : styles.locationCardSmallSelected}
          onClick={() => 
          {
            window.scrollTo(0, 0);
            setSelectedLocation(() => ({
              ...location,
              geoLocation: { ...location?.geoLocation, zoom: 15 },
            }));
          }}>
          <div className="logo">
            {location?.logo?.map((logo, index) =>
              logo?.src && <img key={index} src={logo?.src} alt="logo"/>
            )}
          </div>
          <p css={styles.locationCardSmallTitle}>{location?.title}</p>
          <pre css={styles.locationCardSmallSubtitle}>
            {location?.subtitle}
          </pre>
          <pre>{location?.address}</pre>
          <p>{location?.phone}</p>
          <p css={styles.breakWord}>{location?.email}</p>
          <p css={styles.breakWord}>{location?.website}</p>
          <button
            type="button"
            onClick={() => 
            {
              window.open(
                `https://www.google.com/maps/dir//${location?.geoLocation?.latitude},${location?.geoLocation?.longitude}/@${location?.geoLocation?.latitude},${location?.geoLocation?.longitude},17z/data=!4m2!4m1!3e0?entry=ttu`
              );
            }}>
            Route
          </button>
        </div>
      )
    )}
  </div>
));
