import GridItem from "@/components/buildingBlocks/grids/gridItem/GridItem";
import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenImageWithTextAndCtaGrid } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./ImageWithTextAndCtaGrid.style";

const ImageWithTextAndCtaGrid: FunctionComponent<IGenImageWithTextAndCtaGrid> = ({ gridItems, headline }) => (
  <ContentWrapper css={styles.wrapper}>
    <h2>{headline}</h2>
    <div css={styles.gridWrapper}>
      {gridItems?.map((gridItem, index) =>
      {
        if(!gridItem)
        {
          return null;
        }

        const hasTwoItemsOnLastRow = gridItems?.length % 3 === 2;
        let isLastTwoItems;

        if(hasTwoItemsOnLastRow && (index >= gridItems.length - 2))
        {
          isLastTwoItems = true;
        }

        return (
          <GridItem
            css={isLastTwoItems && styles.lastTwoItems}
            key={gridItem.id}
            ctaButtonLinkTarget={gridItem.ctaButtonLinkTarget}
            ctaButtonText={gridItem.ctaButtonText}
            richText={gridItem.text?.json}
            title={gridItem.title}
            image={gridItem.image}
          />
        );
      })}
    </div>
  </ContentWrapper>
);

export default ImageWithTextAndCtaGrid;
