import ComponentsRenderer from "@/components/common/componentsRenderer/ComponentsRenderer";
import PageWrapper from "@/components/common/pageWrapper/PageWrapper";
import { type GetSlugPagePropsResult } from "@/pages/[slug]";

import React, { type FunctionComponent } from "react";

const Page: FunctionComponent<GetSlugPagePropsResult> = ({
  footer,
  header,
  pageProps,
  seo,
}) => 
{
  if(!pageProps) 
  {
    return null;
  } 

  return (
    <PageWrapper
      footerProps={footer}
      websiteSeo={seo}
      pageSeo={pageProps.pageSeo}
      pageTitle={pageProps.title || ""}
      headerProps={{
        ...header,
        isHeaderFloating: pageProps.headerFloating || false,
      }}>
      <ComponentsRenderer components={pageProps.components} isHeaderFloating={!!pageProps.headerFloating}/>
    </PageWrapper>
  );
};

export default Page;
