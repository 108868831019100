import { colors } from "@/styles/styleVariables";

import { css } from "@emotion/react";

export const wrapper = css`
  width: 100%;
  height: 60rem;
  max-height: 80vh;
  position: relative;
`;

export const overlay = css`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 24.48%, rgba(0, 0, 0, 0) 69.79%, rgba(0, 0, 0, 0.55) 100%);
  z-index: 2;
`;

export const image = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const contentWrapper = css`
  position: absolute;
  bottom: clamp(2.5rem, 5vw, 6rem);
  z-index: 3;
`;

export const title = css`
  font-size: clamp(2.6rem, 5vw, 5rem);
  line-height: 130%;
  font-weight: 600;
  max-width: 100rem;
  color: ${colors.white};
`;
