import { colors } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  h2 {
    font-weight: 500;
    font-size: 6rem;
    margin-bottom: 6rem;
  }

  @media (max-width: ${breakpoints.tablet1024}px)
  {
    h2 {
      font-size: 4rem;
    }
  }

  @media (max-width: ${breakpoints.mobile500}px)
  {
    h2 {
      font-size: 3rem;
      line-height: 130%;
    }
  }
`;

export const gridWrapper = css`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3,1fr);

  @media (max-width: ${breakpoints.tablet1024}px)
  {
    grid-template-columns: repeat(2,1fr);
  }

  @media (max-width: ${breakpoints.tablet768}px)
  {
    grid-template-columns: repeat(1,1fr);
  }
`;

export const iframeWrapper = css`
  border: 1px solid ${colors.gray["100"]};
  
  iframe{
    border: none;
    width: 100%;
    aspect-ratio: 16/9;
  }
  
  p {
    padding: 2.8rem;
    font-size: 1.8rem;
    color: ${colors.slate["900"]};
  }
`;
