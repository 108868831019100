import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenTextWithImagesVariant2 } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent } from "react";

import * as styles from "./TextWithImagesVariant2.style";

const TextWithImagesVariant2: FunctionComponent<IGenTextWithImagesVariant2> = ({
  bottomRightImage,
  bottomRightImageDescription,
  preTitle,
  text,
  title,
  topRightImage,
  topRightImageDescription
}) => (
  <ContentWrapper css={styles.wrapper}>
    <div css={styles.contentContainer}>
      {preTitle && <h3>{preTitle}</h3>}
      {title && <h2>{title}</h2>}
      {text && (
        <div css={styles.richTextWrapper}>
          <RichTextRenderer node={text.json}/>
        </div>
      )}
    </div>
    <div css={styles.imageContainer}>
      <div css={styles.topImageWrapper}>
        <UnpicImage
          src={topRightImage?.src || ""}
          alt={topRightImage?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
      <p css={styles.topRightImageDescription}>{topRightImageDescription}</p>
      <div css={styles.bottomImageWrapper}>
        <UnpicImage
          src={bottomRightImage?.src || ""}
          alt={bottomRightImage?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
      <p>{bottomRightImageDescription}</p>
    </div>
  </ContentWrapper>
);

export default TextWithImagesVariant2;
