import { type IGenHeroBottomBox } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./BottomBoxes.style";

interface IProps
{
  readonly boxes: (IGenHeroBottomBox | null)[] | undefined | null;
}

const BottomBoxes: FunctionComponent<IProps> = ({ boxes }) =>
{
  if(!boxes || boxes.length === 0)
  {
    return null; 
  }

  return (
    <div css={styles.wrapper}>
      {boxes.length > 1 && (
        <div css={styles.bottomBoxesSeparatorLine}/>
      )}
      {boxes.map((box, index) => (
        <div key={index} css={styles.bottomBox}>
          {box?.preTitle && <p css={styles.bottomBoxPreTitle}>{box.preTitle}</p>}
          {box?.content && <p css={styles.bottomBoxTitle}>{box.content}</p>}
        </div>
      ))}
    </div>
  );
};

export default BottomBoxes;
