import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenTextVariant1 } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent } from "react";

import * as styles from "./TextVariant1.style";

const TextVariant1: FunctionComponent<IGenTextVariant1> = ({
  richtextDescription, title
}) => 
{
  return (
    <ContentWrapper css={styles.wrapper}>
      <div css={styles.textBox}>
        {title && <h2>{title}</h2>}
        {richtextDescription && (
          <RichTextRenderer node={richtextDescription.json}/>
        )}
      </div>
    </ContentWrapper>
  );
};

export default TextVariant1;
