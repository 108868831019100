import { contentWrapperMargin } from "@/styles/globals.style";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

const heightAspectRatio = 1.6;

export const scrollContainer = css`
  overflow-x: scroll;
  display: flex;
  width: 100%;
  margin: -20px 0;
  padding: 40px 0;
  user-select: none;
  position: relative;
  cursor: grab;
`;

export const slidesWrapper = css`
  display: flex;
  position: relative;
  > div:first-of-type {
    margin-left: ${contentWrapperMargin};
  }
`;

export const imageWrapper = css`
  width: min(27vw, 46rem);
  height: calc(min(27vw, 46rem) * ${heightAspectRatio});
  max-height: 80vh;
  margin-right: clamp(1.5rem, 2vw, 2.5rem);
  @media screen and (max-width: ${breakpoints.desktop1366}px) {
    width: 34vw;
    height: calc(34vw * ${heightAspectRatio});
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 66vw;
    height: calc(66vw * ${heightAspectRatio});
  }
`;

export const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
