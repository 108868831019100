import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenSubpageHeaderVariant1 } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./SubpageHeaderVariant1.style";

interface IProps
{
  readonly isHeaderFloating: boolean;
}

const SubpageHeaderVariant1: FunctionComponent<IGenSubpageHeaderVariant1 & IProps> = ({
  image, isHeaderFloating, logo
}) =>
{
  if(!image || !logo)
  {
    return null;
  }

  return (
    <div css={styles.wrapper(isHeaderFloating ? 0 : 8)}>
      <UnpicImage
        css={styles.image}
        src={image?.src || ""}
        alt={image?.title || ""}
        layout="fullWidth"
        background="auto"
      />
      <ContentWrapper>
        <div css={styles.bottomBar}>
          <div css={styles.logoWrapper}>
            <UnpicImage
              src={logo?.src || ""}
              alt={logo?.title || ""}
              layout="fullWidth"
            />
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default SubpageHeaderVariant1;
