/* eslint-disable max-lines */
import AlertDialog from "@/components/common/alertDialog/AlertDialog";
import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { env } from "@/env.mjs";
import { type SendMailRequestBody } from "@/pages/api/email";
import { type IGenFormVariant1 } from "@/services/graphql/__generated/sdk";

import { LoadingButton } from "@mui/lab";
import {
  TextField, Typography, FormControlLabel, Radio, RadioGroup, Button, Checkbox
} from "@mui/material";
import axios from "axios";
import Link from "next/link";
import React, {
  type FunctionComponent, type HTMLInputTypeAttribute, useEffect, useRef, useState 
} from "react";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import {
  useForm, Controller, type SubmitHandler, type DefaultValues
} from "react-hook-form";

import CountrySelect from "./countrySelect/CountrySelect";
import * as styles from "./FormVariant1.style";

type TGenderRadioButtonValue = "female" | "male" | "other";

export interface ICountry
{
  code: string;
  label: string;
  phone: string;
}

export type IFormInputNames =
{
  city: string;
  country: ICountry | null;
  email: string;
  firstName: string;
  gender: TGenderRadioButtonValue;
  lastName: string;
  message: string;
  newsletter: boolean;
  phone: string;
  privacyPolicy: boolean;
  streetAndNumber: string;
  trendbook: boolean;
  zipCode: string;
};

type TInputType = "textField" | "textArea" | "select" | "radioGroup" | "checkBox";

interface ICommonFormInputProps
{
  inputType: TInputType;
  isInlineElement?: boolean;
  isOptional?: boolean;
  label: string;
  name: keyof IFormInputNames;
}

interface ITextField extends ICommonFormInputProps
{
  inputType: "textField";
  placeholder: string;
  textFieldInputType?: HTMLInputTypeAttribute;
}

interface ITextArea extends ICommonFormInputProps
{
  inputType: "textArea";
  numberOfRows?: number;
  placeholder: string;
}

interface ISelect extends ICommonFormInputProps
{
  inputType: "select";
  placeholder: string;
}

interface IRadioGroup extends ICommonFormInputProps
{
  inputType: "radioGroup";
  radioButtonLabels: string[];
  radioButtonType: "standard" | "outlined";
  radioButtonValues: TGenderRadioButtonValue[];
  type?: HTMLInputTypeAttribute;
}

interface ICheckBox extends ICommonFormInputProps
{
  inputType: "checkBox";
}

type TFormInput = ITextField | ITextArea | ISelect | IRadioGroup | ICheckBox;
type TInputFieldVariantType = "standard" | "filled" | "outlined";

const FormVariant1: FunctionComponent<IGenFormVariant1> = ({
  city,
  country,
  description,
  dialogConfirmButtonText,
  dialogDescription,
  dialogTitle,
  downloadAssetButton,
  email,
  firstName,
  formInfoText,
  genderSelect,
  image,
  isDarkTheme,
  isDarkThemeFullScreen,
  lastName,
  message,
  newsletter,
  phone,
  privacyPolicy,
  streetAndNumber,
  submitButton,
  title,
  zipCode
}) =>
{
  const recaptchaRef = useRef<ReCAPTCHA>();
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const [formWrapperHeight, setFormWrapperHeight] = useState(0);
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isTrendbookForm = true;

  useEffect(() => 
  {
    const handleResize = (): void =>
    {
      if(formWrapperRef.current)
      {
        setFormWrapperHeight(formWrapperRef.current.offsetHeight);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => 
    {
      window.removeEventListener("resize", handleResize);
    };
  }, [formWrapperRef]);

  const inputFieldVariant: TInputFieldVariantType = "outlined";

  const onSubmit: SubmitHandler<IFormInputNames> = async data =>
  {
    if(!recaptchaRef.current)
    {
      throw new Error("recaptchaRef.current is null");
    }

    const token = await recaptchaRef.current?.executeAsync();

    if(!token)
    {
      throw new Error("token is null");
    }

    const requestBody: SendMailRequestBody = {
      body: data as any,
      recaptchaToken: token
    };
    
    return axios.post("/api/email", requestBody, {
      headers: {
        "x-send-mail-secret": process.env.NEXT_PUBLIC_SEND_MAIL_SECRET
      }
    });
  };

  const formInputData: TFormInput[] = [
    {
      inputType: "radioGroup",
      label: "Gender",
      name: "gender",
      radioButtonLabels: [genderSelect?.female || "", genderSelect?.male || "", genderSelect?.divers || ""],
      radioButtonType: "outlined",
      radioButtonValues: ["female", "male", "other"]
    },
    {
      inputType: "textField",
      label: firstName?.label || "",
      name: "firstName",
      placeholder: firstName?.placeholder || firstName?.label || ""
    },
    {
      inputType: "textField",
      isInlineElement: true,
      label: lastName?.label || "",
      name: "lastName",
      placeholder: lastName?.placeholder || lastName?.label || ""
    },
    {
      inputType: "textField",
      label: streetAndNumber?.label || "",
      name: "streetAndNumber",
      placeholder: streetAndNumber?.placeholder || streetAndNumber?.label || ""
    },
    {
      inputType: "textField",
      label: zipCode?.label || "",
      name: "zipCode",
      placeholder: zipCode?.placeholder || zipCode?.label || "",
      textFieldInputType: "number"
    },
    {
      inputType: "textField",
      label: city?.label || "",
      name: "city",
      placeholder: city?.placeholder || city?.label || ""
    },
    {
      inputType: "select",
      label: country?.label || "",
      name: "country",
      placeholder: country?.placeholder || country?.label || ""
    },
    {
      inputType: "textField",
      label: phone?.label || "",
      name: "phone",
      placeholder: phone?.placeholder || phone?.label || ""
    },
    {
      inputType: "textField",
      label: email?.label || "",
      name: "email",
      placeholder: email?.placeholder || email?.label || ""
    },
    {
      inputType: "textArea",
      label: message?.label || "",
      name: "message",
      placeholder: message?.placeholder || message?.label || ""
    },
    {
      inputType: "checkBox",
      label: privacyPolicy?.label || "",
      name: "privacyPolicy",
    },
    {
      inputType: "checkBox",
      isOptional: true,
      label: newsletter?.label || "",
      name: "newsletter"
    }
  ];

  const filteredFormInputData = formInputData.filter(data => data.label !== "");

  const defaultValues: DefaultValues<IFormInputNames> = {
    city: "",
    country: null,
    email: "",
    firstName: "",
    gender: "female",
    lastName: "",
    message: "",
    newsletter: false,
    phone: "",
    privacyPolicy: false,
    streetAndNumber: "",
    trendbook: isTrendbookForm,
    zipCode: ""
  };

  for(const key in defaultValues)
  {
    if(Object.prototype.hasOwnProperty.call(defaultValues, key))
    {
      const index = filteredFormInputData.findIndex(data => data.name === key);

      if(index === -1 && key in defaultValues && key !== "trendbook")
      {
        delete defaultValues[key as keyof IFormInputNames];
      }
    }
  }

  const {
    control,
    formState,
    handleSubmit,
    reset
  } = useForm<IFormInputNames>({ defaultValues, mode: "onChange" });

  const inputsMarginBottom = "30px";

  return (
    <ContentWrapper css={[!isDarkTheme && styles.wrapper, isDarkTheme && isDarkThemeFullScreen && styles.wrapperDarkThemeFullWidth]}>
      <div css={[isDarkTheme ? styles.imageWrapperDarkTheme(`${formWrapperHeight + 200}px`) : styles.imageWrapper]}>
        <UnpicImage
          src={image?.src || ""}
          alt={image?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
      <div css={[isDarkTheme ? styles.formWrapperDarkTheme : styles.formWrapper]} ref={formWrapperRef}>
        {title && (
          <Typography variant="h2" mb="0.2em">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body1" mb="28px">
            {description}
          </Typography>
        )}
        {downloadAssetButton && downloadAssetButton.title !== "" && downloadAssetButton.link?.__typename === "LinkTypExternal" && (
          <Button
            variant="contained"
            type="button"
            style={{ marginBottom: "28px" }}
            href={downloadAssetButton.link.url || ""}
            component={Link}
            target="_blank">
            {downloadAssetButton.title}
          </Button>
        )}
        {(title || description || (downloadAssetButton && downloadAssetButton.title !== "")) && (
          <div css={styles.separatorLine}/>
        )}
        {formInfoText && (
          <Typography variant="body2" mb="28px">
            {formInfoText}
          </Typography>
        )}
        <form onSubmit={async (e) =>
        {
          e.preventDefault();
          setIsLoading(true);

          const submit = handleSubmit(onSubmit);

          try
          {
            await submit();
            reset();
            setIsSuccessAlertOpen(true);
          }
          catch (err)
          {
            console.log("error while submitting form", err);
            setIsErrorAlertOpen(true);
          }
          finally
          {
            setIsLoading(false);
          }
        }}>
          <div css={styles.innerFormWrapper}>
            {filteredFormInputData.map((inputData, index) => (
              <Controller
                key={index}
                name={inputData.name}
                control={control}
                rules={{ required: !inputData.isOptional }}
                render={({ field }) =>
                {
                  switch (inputData.inputType)
                  {
                    case "textField":
                    case "textArea": {
                      return (
                        <TextField
                          {...field}
                          multiline={inputData.inputType === "textArea"}
                          rows={inputData.inputType === "textArea" ? (inputData.numberOfRows || 5) : 1}
                          css={isDarkTheme && styles.transparentAutofillBackground}
                          sx={{ marginBottom: inputsMarginBottom, marginLeft: inputData.isInlineElement ? "2%" : "0", width: (inputData.isInlineElement || filteredFormInputData[index + 1].isInlineElement) ? "49%" : "100%" }}
                          type={inputData.inputType === "textArea" ? "text" : inputData.textFieldInputType}
                          inputProps={{ style: { fontSize: "16px" } }}
                          InputLabelProps={{ shrink: true }}
                          variant={inputFieldVariant}
                          color={isDarkTheme ? "secondary" : "primary"}
                          label={inputData.label}
                          placeholder={inputData.placeholder}
                        />
                      );
                    }
                    case "select": {
                      return (
                        <CountrySelect
                          field={field}
                          inputFieldLabel={inputData.label}
                          inputFieldPlaceholder={inputData.placeholder}
                          inputFieldVariant={inputFieldVariant}
                          inputsMarginBottom={inputsMarginBottom}
                          isDarkTheme={!!isDarkTheme}
                        />
                      );
                    }
                    case "radioGroup": {
                      return (
                        <RadioGroup
                          {...field}
                          aria-label={inputData.name}
                          css={styles.radioGroup}
                          sx={{ marginBottom: inputsMarginBottom }}
                          row>
                          {inputData.radioButtonLabels.map((label, index) => (
                            <div
                              key={index}
                              css={[styles.radioButton,
                                index === 0 && styles.firstRadioButton,
                                index === inputData.radioButtonLabels.length - 1 && styles.lastRadioButton,
                                index !== 0 && index !== inputData.radioButtonLabels.length - 1 && styles.middleRadioButton
                              ]}>
                              <FormControlLabel
                                value={label}
                                control={<Radio/>}
                                label={<div style={{ fontSize: "16px" }}>{label}</div>}
                              />
                            </div>
                          ))}
                        </RadioGroup>
                      );
                    }
                    case "checkBox": {
                      return (
                        <div css={styles.radioButtonAndLabelWrapper(inputsMarginBottom)}>
                          <Checkbox {...field} id={field.name}/>
                          <label css={styles.checkBoxLabel} htmlFor={field.name}>{inputData.label}</label>
                        </div>
                      );
                    }
                  }
                }}
              />
            ))}
          </div>
          <LoadingButton
            disabled={!formState.isValid}
            fullWidth
            loading={isLoading}
            variant="contained"
            type="submit">
            {submitButton?.title || ""}
          </LoadingButton>
          <ReCAPTCHA
            ref={recaptchaRef as any}
            size="invisible"
            sitekey={env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          />
        </form>
      </div>
      <AlertDialog
        confirmButtonText={dialogConfirmButtonText || ""}
        description={dialogDescription || ""}
        isAlertOpen={isSuccessAlertOpen}
        setIsAlertOpen={setIsSuccessAlertOpen}
        title={dialogTitle || ""}
      />
      <AlertDialog
        confirmButtonText="OK"
        description="We are sorry, something went wrong while sending your request. Please try again later."
        isAlertOpen={isErrorAlertOpen}
        setIsAlertOpen={setIsErrorAlertOpen}
        title="Something went wrong"
      />
    </ContentWrapper>
  );
};

export default FormVariant1;
