import GridItem from "@/components/buildingBlocks/grids/gridItem/GridItem";
import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenTextWithCtaGrid } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./TextWithCtaGrid.style";

const TextWithCtaGrid: FunctionComponent<IGenTextWithCtaGrid> = ({ gridItems, headline }) => (
  <ContentWrapper css={styles.wrapper}>
    <h2>{headline}</h2>
    <div css={styles.gridWrapper}>
      {gridItems?.map((gridItem) =>
      {
        if(!gridItem)
        {
          return null;
        }

        return (
          <GridItem
            key={gridItem.id}
            ctaButtonLinkTarget={gridItem.ctaButtonLinkTarget}
            ctaButtonText={gridItem.ctaButtonText}
            richText={gridItem.text?.json}
            title={gridItem.title}
          />
        );
      })}
    </div>
  </ContentWrapper>
);

export default TextWithCtaGrid;
