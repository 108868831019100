import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenGalleryVariant1 } from "@/services/graphql/__generated/sdk";
import { getPageSlug } from "@/utils/getPageSlug";

import Link from "next/link";
import React, { type FunctionComponent } from "react";

import * as styles from "./GalleryVariant1.style";

const GalleryVariant1: FunctionComponent<IGenGalleryVariant1> = ({ images }) => 
{
  if(images == null) 
  {
    return null;
  }

  return (
    <ContentWrapper css={styles.grid}>
      {images.map((image, index) =>
      {
        if(image?.linkedCompanyDetailPage) 
        {
          return (
            <Link key={index} href={getPageSlug(image?.linkedCompanyDetailPage)}>
              <div key={index} css={styles.wrapper}>
                <UnpicImage
                  src={image?.image?.src || ""}
                  alt={image?.image?.title || ""}
                  layout="fullWidth"
                  background="auto"
                />
                <div css={styles.bottomBar}>
                  <div css={styles.logoWrapper}>
                    <UnpicImage
                      css={styles.logo(image.logo?.scalingFactor || 100)}
                      src={image?.logo?.src || ""}
                      alt={image?.logo?.title || ""}
                      layout="fullWidth"
                    />
                  </div>
                </div>
              </div>
            </Link>
          );
        }
        
        return (
          <div key={index} css={styles.wrapper}>
            <UnpicImage
              src={image?.image?.src || ""}
              alt={image?.image?.title || ""}
              layout="fullWidth"
              background="auto"
            />
            <div css={styles.bottomBar}>
              <div css={styles.logoWrapper}>
                <UnpicImage
                  src={image?.logo?.src || ""}
                  alt={image?.logo?.title || ""}
                  layout="fullWidth"
                />
              </div>
            </div>
          </div>
        );
      })}
    </ContentWrapper>
  );
};

export default GalleryVariant1;
