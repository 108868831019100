import { colors } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

const columnViewMaxWidthInPx = breakpoints.tablet1024;

export const wrapper = css`
  display: flex;
  direction: rtl;  
  background-color: #F8FAFC;
  
  @media screen and (max-width: ${columnViewMaxWidthInPx}px) {
    flex-direction: column;
  }
`;

export const wrapperDarkThemeFullWidth = css`
  max-width: none;
  width: 100%;
  margin-bottom: calc(-1 * clamp(7rem, 11vw, 14rem));
`;

export const imageWrapper = css`
  position: relative;
  flex: 6;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const imageWrapperDarkTheme = (heightInPx: string) => css`
  width: 100%;
  height: ${heightInPx};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const formWrapper = css`
  flex: 4;
  min-width: 450px;
  padding: 60px 60px 72px;
  min-height: 600px;
  direction: ltr;

  @media screen and (max-width: ${columnViewMaxWidthInPx}px) {
    min-width: unset;
    padding: 8%;
  }
`;

export const formWrapperDarkTheme = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: min(80vw, 757px);
  padding: 60px 60px 72px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(25px);
  
  h2{
    color: ${colors.white};
  }

  @media screen and (max-width: ${columnViewMaxWidthInPx}px) {
    padding: 8%;
  }
`;

export const innerFormWrapper = css`
  display: flex;
  flex-wrap: wrap;
`;

export const separatorLine = css`
  height: 1px;
  background-color: ${colors.slate["300"]};
  margin-bottom: 28px;
`;

export const textField = css`
  border: ${colors.gray["200"]} solid 1px;
  padding: 12px 10px;
  border-radius: 5px;
  font-size: 16px;
`;

export const radioGroup = css`
  label{
    margin-right: .2rem;
  }
`;

export const radioButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${colors.slate["200"]} solid 1px;
  width: 20rem;
  height: 5.6rem;

  @media screen and (min-width: ${breakpoints.mobile500 + 1}px) {
    flex: 1;
  }
  
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    width: 100%;
    justify-content: flex-start;
    padding-left: 10px;
  }
`;

const radioButtonBorderRadius = "5px";

export const firstRadioButton = css`
  border-top-left-radius: ${radioButtonBorderRadius};

  @media screen and (min-width: ${breakpoints.mobile500 + 1}px) {
    border-bottom-left-radius: ${radioButtonBorderRadius};
  }
  
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    border-top-right-radius: ${radioButtonBorderRadius};
  }
`;

export const middleRadioButton = css`
  @media screen and (min-width: ${breakpoints.mobile500 + 1}px) {
    border-left: none;
  }

  @media screen and (max-width: ${breakpoints.mobile500}px) {
    border-top: none;
  }
`;

export const lastRadioButton = css`
  border-bottom-right-radius: ${radioButtonBorderRadius};

  @media screen and (min-width: ${breakpoints.mobile500 + 1}px) {
    border-top-right-radius: ${radioButtonBorderRadius};
    border-left: none;
  }

  @media screen and (max-width: ${breakpoints.mobile500}px) {
    border-bottom-left-radius: ${radioButtonBorderRadius};
    border-top: none;
  }
`;

export const radioButtonAndLabelWrapper = (marginBottom: string) => css`
  display: flex;
  align-items: center;
  margin-bottom: ${marginBottom};
`;

export const checkBoxLabel = css`
  font-size: 14px;
  
  :hover{
    cursor: pointer;
  }
`;

// TODO: check if this is the right way

export const transparentAutofillBackground = css`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: white !important
  }
`;
