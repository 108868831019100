import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenVideoLibraryVariant1 } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./VideoLibraryVariant1.style";

const VideoLibraryVariant1: FunctionComponent<IGenVideoLibraryVariant1> = ({ title, videos }) =>
{
  return (
    <ContentWrapper css={styles.wrapper}>
      <h2>{title}</h2>
      <div css={styles.gridWrapper}>
        {videos?.map(video =>
        {
          if(!video || !video.videourl)
          {
            return null;
          }

          let videoId = "";

          if(video.plattform === "youtube")
          {
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = video.videourl?.match(regExp);
            videoId = (match && match[7].length === 11) ? match[7] : "";
          }
          else
          {
            const match = /vimeo.*\/(\d+)/i.exec(video?.videourl ?? "");

            if(match)
            {
              // eslint-disable-next-line prefer-destructuring
              videoId = match[1];
            }
          }

          return (
            <div css={styles.iframeWrapper} key={video.id}>
              <iframe
                src={video.plattform === "youtube" ? "https://www.youtube.com/embed/" + videoId : "https://player.vimeo.com/video/" + videoId}
                allowFullScreen
              />
              <p>{video.description}</p>
            </div>
          );
        })}
      </div>
    </ContentWrapper>
  );
};

export default VideoLibraryVariant1;
