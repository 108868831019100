import { colors, contentAreaWidth } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = (nonOverlappingHeaderHeightInRem: number) => css`
  width: 100%;
  height: calc(100vh - ${nonOverlappingHeaderHeightInRem}rem); // fallback for browsers that do not support svh
  height: calc(100svh - ${nonOverlappingHeaderHeightInRem}rem);
  position: relative;
`;

export const image = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const contentWrapper = css`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const content = css`
  position: absolute;
  left: 0;
  top: 42%;
  transform: translateY(-50%);
  color: ${colors.textDarkBg};
  max-width: ${contentAreaWidth / 2}px;
`;

export const preTitle = css`
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    font-size: 1.5rem;
  }
`;

export const title = css`
  font-size: clamp(3rem, 7.5vw, 7.2rem);
  line-height: 130%;
  color: white;
`;

export const subTitle = css`
  font-size: clamp(1.5rem, 2.2vw, 2rem);
  font-weight: 300;
  letter-spacing: 0.005em;
  margin-top: .6em;
  p{
    color: white;
  }
`;

export const ctaWrapper = css`
  margin-top: clamp(3rem, 6vw, 6rem);
`;

export const bottomBoxesContentWrapper = css`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    justify-content: center;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 100%;
  }
`;
