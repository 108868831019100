import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const grid = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(2.5rem, 2vw, 3rem);
  
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    grid-template-columns: 1fr;
  }
`;

export const wrapper = css`
  position: relative;
  display: flex;
  height: min(24vw, 38rem); // height is 24vw, but 38rem is the max height
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    height: 37vw;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    height: 72vw;
  }
`;

export const bottomBar = css`
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
`;

export const logoWrapper = () => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70%;
  
  img {
    object-fit: contain !important;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const logo = (scaleInPercentage: number) => css`
  transform: scale(calc(${scaleInPercentage}/100));
`;

