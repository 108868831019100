/* eslint-disable max-lines, max-len */
import {
  type IGenMap,
  type IGenMapLocation,
  type Maybe,
} from "@/services/graphql/__generated/sdk";

import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { Button } from "@mui/material";
import React, {
  type FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";

import { LocationsList } from "./LocationsList";
import * as styles from "./MapComponent.style";
import { SelectedLocationCard } from "./SelectedLocationCard";

export const MapComponent: FunctionComponent<IGenMap> = ({ mapLocations, mapType }) => 
{
  const multiLocationsMap = mapType === "Multiple_Locations_Map";
  const [selectedLocation, setSelectedLocation] = useState<Maybe<IGenMapLocation> | undefined>(mapType === "Multiple_Locations_Map" ? undefined : mapLocations?.[0]);
  const LocationsListRef = useRef<HTMLDivElement>(null);
  const googleMapRef = useRef<HTMLDivElement | null>(null);
  const [svg, setSvg] = useState<string | null>(null);
  const googleMapObjectRef = useRef<google.maps.Map>();

  const initialLat = 51.1657;
  const initialLong = 10.4515;
  const initialZoom = 7;

  const buildContent = (property: any): Element =>
  {
    const content = document.createElement("div");
    content.classList.add("property");

    content.innerHTML = `
    <div
        class="icon"
        style="border:1px solid black;background:white;border-radius:10px;width:40px;height:40px;position:relative;display: flex; justify-content: center; align-items: center;">
    <img
      src=${property.src}
      alt=""
      width="100%"
      style="object-fit:contain; padding: 5px;"
    />
    <div style="position: absolute; bottom: -32px;">
      <svg
        width="10"
        height="27"
        viewBox="0 0 10 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 27L0.669872 0H9.33013L5 27Z" fill="#0F172A" />
      </svg>
      <div></div>
    </div>
  </div>  
          `;
    return content;
  };

  useEffect(() => 
  {
    const initMap = async (): Promise<void> => 
    {
      setSvg(() => window.btoa(`
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_658_27038)">
        <circle cx="20" cy="20" r="20" fill="#0F172A" fill-opacity="0.2"/>
        <circle cx="20" cy="20" r="19.5" stroke="#0F172A" stroke-opacity="0.1"/>
        </g>
        <circle cx="20" cy="20" r="11" fill="#0F172A"/>
        <defs>
        <filter id="filter0_b_658_27038" x="-4" y="-4" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_658_27038"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_658_27038" result="shape"/>
        </filter>
        </defs>
        </svg>
      `));

      const loader = new Loader({
        apiKey: "AIzaSyASzlRhaWEHUu-PZdlcubaeCiGiR9H8EL4",
        version: "weekly",
      });

      await loader.load();

      if(googleMapRef.current && mapLocations)
      {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
        googleMapObjectRef.current = new google.maps.Map(googleMapRef.current, {
          center: {
            lat: selectedLocation?.geoLocation?.latitude || initialLat,
            lng: selectedLocation?.geoLocation?.longitude || initialLong,
          },
          clickableIcons: false,
          fullscreenControl: false,
          mapId: "390caa7f1119440f",
          mapTypeControl: false,
          scrollwheel: true,
          streetViewControl: false,
          zoom: selectedLocation?.geoLocation?.zoom || 12,
          zoomControl: true,
        });

        const markers = [];
        const bounds = new google.maps.LatLngBounds();

        for(const location of mapLocations) 
        {
          const lat = location?.geoLocation?.latitude;
          const lng = location?.geoLocation?.longitude;

          if(lat && lng) 
          {
            const marker = new AdvancedMarkerElement({
              content: buildContent(location?.logo?.[0]),
              map: googleMapObjectRef.current,
              position: { lat, lng },
            });

            marker.addListener("click", (e: google.maps.MapMouseEvent) =>
            {
              setSelectedLocation(() => ({
                ...location,
                geoLocation: { ...location?.geoLocation, zoom: 15 },
              }));

              googleMapObjectRef.current?.setZoom(15);
              googleMapObjectRef.current?.setCenter(marker.position as google.maps.LatLng);

              const element = location?.id ? document.getElementById(location?.id) : null;

              if(element && LocationsListRef.current)
              {
                e.domEvent.preventDefault();
                e.domEvent.stopPropagation();

                LocationsListRef.current.scrollTo({
                  behavior: "smooth",
                  top: element.offsetTop,
                });
              }
            });

            markers.push(marker);
            bounds.extend(marker.position as google.maps.LatLng);
          }
          if(!selectedLocation) 
          {
            googleMapObjectRef.current?.fitBounds(bounds);
          }
          const renderer = {
            render: ({ count, position }: any) =>
              new google.maps.Marker({
                collisionBehavior: google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
                icon: {
                  scaledSize: new google.maps.Size(45, 45),
                  url: svg ? `data:image/svg+xml;base64,${svg}` : "",
                },
                label: {
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  text: String(count),
                },
                opacity: 0.9,
                optimized: true,
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              }),
          };

          new MarkerClusterer({
            map: googleMapObjectRef.current,
            markers,
            renderer,
          });
        }
      }
    };
    void initMap();
  }, [selectedLocation, mapLocations, multiLocationsMap, svg]);

  return (
    <div css={styles.wrapper}>
      {multiLocationsMap && (
        <LocationsList
          multiLocationsMap={multiLocationsMap}
          ref={LocationsListRef}
          mapLocations={mapLocations}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      )}
      <div
        css={[styles.mapWrapper, multiLocationsMap ? styles.mapHeightMultiLocations : styles.mapHeightSingleLocation]}
        ref={googleMapRef}
        className="map-container"
      />
      {multiLocationsMap && (
        <div css={styles.resetMapButtonWrapper}>
          <Button
            type="button"
            variant="contained"
            startIcon={<CenterFocusStrongIcon/>}
            onClick={() =>
            {
              if(selectedLocation) 
              {
                setSelectedLocation(null);
              }
              else
              {
                googleMapObjectRef.current?.setZoom(initialZoom);
                googleMapObjectRef.current?.setCenter({
                  lat: initialLat,
                  lng: initialLong,
                });
              }
            }}>
            Reset Map
          </Button>
        </div>
      )}
      {!multiLocationsMap && selectedLocation && (
        <SelectedLocationCard selectedLocation={selectedLocation}/>
      )}
    </div>
  );
};
