import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    justify-content: start;
  }
`;

export const textBox = css`
  width: 95rem;
  transform: translateX(-5%);
  
  p{
    font-size: 2.8rem;
    font-weight: 300;
  }

  @media screen and (max-width: ${breakpoints.desktop1366}px) {
    transform: none;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    p{
      font-size: 2.6rem;
    }
  }
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    p{
      font-size: 2.4rem;
    }
  }
`;
