import { colors } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const gridItemWrapper = css`
  border: 1px solid ${colors.gray["100"]};
  grid-column: span 2;
`;

export const imageWrapper = css`
  width: 100%;
  height: 30rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const contentWrapper = css`
  padding: 3rem;
  
  h3 {
    color: ${colors.slate["900"]};
    line-height: 1.2;
    font-size: 2.4rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    padding: 2rem;
  }
`;

export const websiteButton = css`
  display: inline-block;
  margin-top: 2.4rem;
  padding: 1.4rem 1.6rem;
  border-radius: .6rem;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, .1);
  color: ${colors.textLightBg};
  font-weight: 500;

  &:hover {
    background-color: #f4f4f4;
  }
`;
