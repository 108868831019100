import { colors } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7.5px);
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 100%;
  }
`;

export const bottomBox = css`
  padding: 4rem;
  width: 30rem;
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 50%;
  }
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    padding: 2rem;
  }
  @media screen and (max-width: ${breakpoints.mobile400}px) {
    padding: 1.5rem;
  }
`;

export const bottomBoxesSeparatorLine = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 80%;
  background-color: rgba(15, 23, 42, 0.1);
  margin-left: -.8rem;
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    margin-left: -.4rem;
  }
`;

export const bottomBoxPreTitle = css`
  color: ${colors.slate["700"]};
  font-size: 1.6rem;
  margin-bottom: 4px;
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    font-size: 1.4rem;
  }
`;

export const bottomBoxTitle = css`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${colors.slate["900"]};
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    font-size: 1.5rem;
  }
`;
