import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  height: 540px;
  display: flex;
  gap: 2rem;
  
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    height: auto;
    flex-direction: column;
  }
`;

export const image1Wrapper = css`
  width: 60%;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 55%;
  }
`;

export const image2Wrapper = css`
  width: 40%;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 45%;
  }
`;

export const imageWrapper = css`
  display: flex;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 50%;
  }
  @media screen and (max-width: ${breakpoints.mobile500}px) {
    width: 100%;
  }
`;
