import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    justify-content: start;
  }
`;

export const textBox = css`
  width: 70rem;
  transform: translateX(-10%);
  
  h2{
    white-space: pre-wrap;
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 4.4rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    transform: none;

    h2{
      font-weight: 500;
    }
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 100%;

    h2{
      white-space: pre-wrap;
      font-size: 3rem;
      font-weight: 700;
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }
  }
`;
