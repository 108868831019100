import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenHeroVariant2 } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./HeroVariant2.style";

const HeroVariant2: FunctionComponent<IGenHeroVariant2> = ({ image, title }) =>
{
  return (
    <div css={styles.wrapper}>
      <UnpicImage
        css={styles.image}
        src={image?.src || ""}
        alt={image?.title || ""}
        layout="fullWidth"
        background="auto"
        cdn="bunny"
      />
      <ContentWrapper css={styles.contentWrapper}>
        {title && <h1 css={styles.title}>{title}</h1>}
      </ContentWrapper>
      <div css={styles.overlay}/>
    </div>
  );
};

export default HeroVariant2;
