import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = (nonOverlappingHeaderHeightInRem: number) => css`
  width: 100%;
  height: calc(100vh - ${nonOverlappingHeaderHeightInRem}rem); // fallback for browsers that do not support svh
  height: calc(100svh - ${nonOverlappingHeaderHeightInRem}rem);
  position: relative;
  display: flex;
`;

export const image = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const bottomBar = css`
  position: absolute;
  bottom: 4rem;
  height: 11.1rem;
  width: 30.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(.7rem);
  box-shadow: 0 1.4rem 3.8rem rgba(0, 0, 0, 0.25);
  border-radius: .6rem;

  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: 100%;
  }
`;

export const logoWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70%;

  img {
    object-fit: contain !important;
    max-height: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet768}px) {
    width: min(25rem, 70%);
  }
`;
