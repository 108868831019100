import { ComponentSelector } from "@/components/common/componentsRenderer/ComponentSelector";
import { type IGenPage } from "@/services/graphql/__generated/sdk";
import { animationProps } from "@/styles/styleVariables";

import { motion } from "framer-motion";
import React, { type FunctionComponent } from "react";

import * as styles from "./ComponentsRenderer.style";

interface Props 
{
  readonly components: IGenPage["components"];
  readonly isHeaderFloating: boolean;
}

const ComponentsRenderer: FunctionComponent<Props> = ({ components, isHeaderFloating }) => (
  <div css={styles.wrapper}>
    {components?.filter(Boolean).map((component, index) =>
    {
      // dont animate first component
      const _animationProps = index === 0 ? {} : animationProps;

      return (
        <motion.div
          key={component?.id}
          css={styles.componentWrapper}
          {..._animationProps}>
          <ComponentSelector component={component} isHeaderFloating={isHeaderFloating}/>
        </motion.div>
      );
    })}
  </div>
);

export default ComponentsRenderer;
