import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenRichtext } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent } from "react";

const Richtext: FunctionComponent<IGenRichtext> = ({ content }) =>
{
  if(!content?.json)
  {
    return null;
  }

  return (
    <ContentWrapper>
      <RichTextRenderer node={content.json}/>
    </ContentWrapper>
  );
};

export default Richtext;
