import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenLogoGalleryVariant1 } from "@/services/graphql/__generated/sdk";
import { getPageSlug } from "@/utils/getPageSlug";

import Link from "next/link";
import React, { type FunctionComponent } from "react";

import * as styles from "./LogoGalleryVariant1.style";

const LogoGalleryVariant1: FunctionComponent<IGenLogoGalleryVariant1> = ({ logos }) =>
{
  if(logos == null)
  {
    return null;
  }

  return (
    <ContentWrapper>
      <div css={styles.wrapper}>
        {logos.filter(Boolean).map((logo, index) =>
        {
          if(logo.__typename === "LogoWithExternalLink")
          {
            return (
              <a
                key={index}
                css={styles.logoWrapper}
                href={logo.url ?? "/"}
                target="_blank"
                rel="noreferrer">
                <img
                  css={styles.logo(logo.logo?.scalingFactor || 100)}
                  src={logo.logo?.src || ""}
                  alt={logo.logo?.title || ""}
                  loading="lazy"
                />
              </a>
            );
          }
          else if(logo.__typename === "LogoWithInternalLink")
          {
            return (
              <Link key={index} css={styles.logoWrapper} href={getPageSlug(logo.linkedCompanyDetailPage)}>
                <img
                  css={styles.logo(logo.logo?.scalingFactor || 100)}
                  src={logo.logo?.src || ""}
                  alt={logo.logo?.title || ""}
                  loading="lazy"
                />
              </Link>
            );
          }

          return;
        })}
      </div>
    </ContentWrapper>
  );
};

export default LogoGalleryVariant1;
