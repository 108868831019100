import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenImageAndTextVariant1 } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent } from "react";

import * as styles from "./ImageAndTextVariant1.style";

const ImageAndTextVariant1: FunctionComponent<IGenImageAndTextVariant1> = ({
  image, richtextDescription, title
}) => 
{
  return (
    <ContentWrapper css={styles.wrapper}>
      <UnpicImage
        css={styles.image}
        src={image?.src || ""}
        alt={image?.title || ""}
        layout="fullWidth"
        background="auto"
      />
      <div css={styles.textBox}>
        {title && <h2>{title}</h2>}
        {richtextDescription && (
          <div>
            <RichTextRenderer node={richtextDescription.json}/>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default ImageAndTextVariant1;
