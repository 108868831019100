import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import { type IGenTextVariant2 } from "@/services/graphql/__generated/sdk";

import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { type FunctionComponent } from "react";

import * as styles from "./TextVariant2.style";

const TextVariant2: FunctionComponent<IGenTextVariant2> = ({
  richtextDescription
}) => 
{
  return (
    <ContentWrapper css={styles.wrapper}>
      <div css={styles.textBox}>
        {richtextDescription && (
          <RichTextRenderer node={richtextDescription.json}/>
        )}
      </div>
    </ContentWrapper>
  );
};

export default TextVariant2;
