import { colors } from "@/styles/styleVariables";
import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = (height: string) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${height};

  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    height: unset;
    flex-direction: column;
  }
`;

export const textBoxAndImageWrapper = css`
  position: relative;
  width: 50%;
  height: min-content;
  padding: 4rem 10% 3rem 0;
  min-height: 63rem;
  
  h2{
    font-size: 3.6rem;
    font-weight: 500;
    color: ${colors.slate["900"]};
    margin-bottom: 1rem;
  }
  
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: auto;
    min-height: 0;
    margin-bottom: 4rem;
    
    h2{
      font-size: 3rem;
      margin-top: 3rem;
    }
  }
`;

export const imageWrapper = css`
  display: flex;
  width: 50%;
  height: 115.2rem;

  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    width: 100%;
    height: 70vh;
  }
`;

export const image2 = (height: string) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  width: 120% !important;
  height: ${height};
  
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    transform: translateY(0);
    position: relative;
    width: 100% !important;
    height: 50vh;
  }
`;
