import CarouselVariant1 from "@/components/buildingBlocks/carouselVariant1/CarouselVariant1";
import FormVariant1 from "@/components/buildingBlocks/formVariant1/FormVariant1";
import FullscreenImage from "@/components/buildingBlocks/fullscreenImage/FullscreenImage";
import GalleryVariant1 from "@/components/buildingBlocks/galleryVariant1/GalleryVariant1";
import ImageWithTextAndCtaGrid from "@/components/buildingBlocks/grids/imageWithTextAndCtaGrid/ImageWithTextAndCtaGrid";
import TextWithCtaGrid from "@/components/buildingBlocks/grids/textWithCtaGrid/TextWithCtaGrid";
import HeroVariant1 from "@/components/buildingBlocks/heroVariant1/HeroVariant1";
import HeroVariant2 from "@/components/buildingBlocks/heroVariant2/HeroVariant2";
import ImageAndTextVariant1 from "@/components/buildingBlocks/imageAndTextVariant1/ImageAndTextVariant1";
import LogoGalleryVariant1 from "@/components/buildingBlocks/logoGalleryVariant1/LogoGalleryVariant1";
import { MapComponent } from "@/components/buildingBlocks/mapComponent/MapComponnent";
import PageTitle from "@/components/buildingBlocks/pageTitle/PageTitle";
import Richtext from "@/components/buildingBlocks/richtext/Richtext";
import SubpageHeaderVariant1 from "@/components/buildingBlocks/subpageHeaderVariant1/SubpageHeaderVariant1";
import TextVariant1 from "@/components/buildingBlocks/textVariant1/TextVariant1";
import TextVariant2 from "@/components/buildingBlocks/textVariant2/TextVariant2";
import TextWithImagesVariant1 from "@/components/buildingBlocks/textWithImagesVariant1/TextWithImagesVariant1";
import TextWithImagesVariant2 from "@/components/buildingBlocks/textWithImagesVariant2/TextWithImagesVariant2";
import ThreeImagesVariant1 from "@/components/buildingBlocks/threeImagesVariant1/ThreeImagesVariant1";
import TwoImagesVariant1 from "@/components/buildingBlocks/twoImagesVariant1/TwoImagesVariant1";
import VideoLibraryVariant1 from "@/components/buildingBlocks/videoLibraryVariant1/VideoLibraryVariant1";
import { type IGenPage_Components } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

interface IComponentSelector 
{
  readonly component: IGenPage_Components;
  readonly isHeaderFloating: boolean;
}

export const ComponentSelector: FunctionComponent<IComponentSelector> = ({ component, isHeaderFloating }) =>
{
  switch (component.__typename)
  {
    case "HeroVariant1": return <HeroVariant1 {...component} isHeaderFloating={isHeaderFloating}/>;
    case "PageTitle": return <PageTitle {...component}/>;
    case "HeroVariant2": return <HeroVariant2 {...component}/>;
    case "FormVariant1": return <FormVariant1 {...component}/>;
    case "ImageAndTextVariant1": return <ImageAndTextVariant1 {...component}/>;
    case "GalleryVariant1": return <GalleryVariant1 {...component}/>;
    case "LogoGalleryVariant1": return <LogoGalleryVariant1 {...component}/>;
    case "SubpageHeaderVariant1": return <SubpageHeaderVariant1 {...component} isHeaderFloating={isHeaderFloating}/>;
    case "FullscreenImage": return <FullscreenImage {...component}/>;
    case "CarouselVariant1": return <CarouselVariant1 {...component}/>;
    case "TextWithImagesVariant2": return <TextWithImagesVariant2 {...component}/>;
    case "ImageWithTextAndCtaGrid": return <ImageWithTextAndCtaGrid {...component}/>;
    case "TextWithCtaGrid": return <TextWithCtaGrid {...component}/>;
    case "TextWithImagesVariant1": return <TextWithImagesVariant1 {...component}/>;
    case "Richtext": return <Richtext {...component}/>;
    case "TextVariant1": return <TextVariant1 {...component}/>;
    case "TextVariant2": return <TextVariant2 {...component}/>;
    case "TwoImagesVariant1": return <TwoImagesVariant1 {...component}/>;
    case "ThreeImagesVariant1": return <ThreeImagesVariant1 {...component}/>;
    case "VideoLibraryVariant1": return <VideoLibraryVariant1 {...component}/>;
    case "Map": return <MapComponent {...component}/>;
    default: {
      console.error(`ComponentSelector: Unknown component type: ${component.__typename}`);
      return null;
    }
  }
};
