import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenThreeImagesVariant1 } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./TwoImagesVariant1.style";

const ThreeImagesVariant1: FunctionComponent<IGenThreeImagesVariant1> = ({
  image1, image2, image3
}) => 
{
  return (
    <ContentWrapper css={styles.contentWrapper}>
      <div css={[styles.image1Wrapper]}>
        <UnpicImage
          css={styles.image}
          src={image1?.src || ""}
          alt={image1?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
      <div css={styles.image2And3OuterWrapper}>
        <div css={[styles.image2Wrapper, styles.image2And3InnerWrapper]}>
          <UnpicImage
            css={styles.image}
            src={image2?.src || ""}
            alt={image2?.title || ""}
            layout="fullWidth"
            background="auto"
          />
        </div>
        <div css={[styles.image3Wrapper, styles.image2And3InnerWrapper]}>
          <UnpicImage
            css={styles.image}
            src={image3?.src || ""}
            alt={image3?.title || ""}
            layout="fullWidth"
            background="auto"
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ThreeImagesVariant1;
