import ContentWrapper from "@/components/common/contentWrapper/ContentWrapper";
import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenTwoImagesVariant1 } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent } from "react";

import * as styles from "./TwoImagesVariant1.style";

const TwoImagesVariant1: FunctionComponent<IGenTwoImagesVariant1> = ({
  image1, image2
}) => 
{
  return (
    <ContentWrapper css={styles.wrapper}>
      <div css={[styles.image1Wrapper, styles.imageWrapper]}>
        <UnpicImage
          src={image1?.src || ""}
          alt={image1?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
      <div css={[styles.image2Wrapper, styles.imageWrapper]}>
        <UnpicImage
          src={image2?.src || ""}
          alt={image2?.title || ""}
          layout="fullWidth"
          background="auto"
        />
      </div>
    </ContentWrapper>
  );
};

export default TwoImagesVariant1;
