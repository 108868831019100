import { breakpoints } from "@/utils/breakPoints";

import { css } from "@emotion/react";

export const wrapper = css`

`;

export const image = css`
  object-fit: cover;
  max-height: 80vh;

  @media screen and (max-width: ${breakpoints.tablet768}px) {
    max-height: 40vh;
  }
`;
