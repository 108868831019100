
import { Autocomplete, TextField } from "@mui/material";
import React, { type FunctionComponent } from "react";
import { type ControllerRenderProps } from "react-hook-form";

import { countries } from "./countries";
import { type ICountry, type IFormInputNames } from "../FormVariant1";

interface IProps
{
  readonly field: ControllerRenderProps<IFormInputNames, keyof IFormInputNames>;
  readonly inputFieldLabel: string;
  readonly inputFieldPlaceholder: string;
  readonly inputFieldVariant: "standard" | "filled" | "outlined";
  readonly inputsMarginBottom: string;
  readonly isDarkTheme: boolean;
}

const CountrySelect: FunctionComponent<IProps> = ({
  field,
  inputFieldLabel,
  inputFieldPlaceholder,
  inputFieldVariant,
  inputsMarginBottom,
  isDarkTheme
}) =>
{
  const countriesSorted = countries.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Autocomplete
      {...field}
      value={(field.value as ICountry)}
      fullWidth
      sx={{ marginBottom: inputsMarginBottom }}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      options={countriesSorted}
      getOptionLabel={country => country.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputFieldLabel}
          placeholder={inputFieldPlaceholder}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
            style: { fontSize: "16px" },
          }}
          variant={inputFieldVariant}
          color={isDarkTheme ? "secondary" : "primary"}
        />
      )}
      onChange={(_, data) => field.onChange(data)}
    />
  );
};

export default CountrySelect;
