export const isFileLink = (link: string): boolean =>
{
  const fileExtensions = [
    ".txt", ".doc", ".pdf", ".jpg", ".png", ".xlsx", ".ppt", ".csv", ".mp3", ".mp4",
    ".ics", ".vcs", ".pst", ".csv", ".xlsx", ".icbu", ".ical", ".ifb", ".xcs", ".icsx",
    ".icalendar", ".icsml", ".docx", ".jpeg", ".gif", ".svg", ".bmp", ".ico", ".xls",
    ".pptx", ".mov", ".avi", ".wmv", ".flv", ".zip", ".rar", ".tar", ".7z", ".gz", ".dmg"
  ];
  const fileExtension = link.substring(link.lastIndexOf("."));
  return fileExtensions.includes(fileExtension);
};
