import { css } from "@emotion/react";

import { breakpoints } from "../../../utils/breakPoints";

export const wrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const image = css`
  object-fit: cover;
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    max-height: 40vh;
  }
`;

export const textBox = css`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 728px;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);
  padding: 40px;

  *{
    color: white;
  }
  
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    position: relative;
    background-color: #000;
    padding: 30px;
  }
`;
