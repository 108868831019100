import UnpicImage from "@/components/common/unpicImage/UnpicImage";
import { type IGenCarouselVariant1 } from "@/services/graphql/__generated/sdk";

import React, { type FunctionComponent, useRef } from "react";
import { ScrollContainer } from "react-indiana-drag-scroll";

import * as styles from "./CarouselVariant1.style";
import * as globalStyles from "../../../styles/globals.style";

const CarouselVariant1: FunctionComponent<IGenCarouselVariant1> = ({ images }) =>
{
  const sliderWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <ScrollContainer ref={sliderWrapperRef as any} css={[globalStyles.scrollbarHidden, styles.scrollContainer]}>
      <div css={styles.slidesWrapper}>
        {images?.filter(Boolean).map(({ src, title }, index) => (
          <div key={index} css={styles.imageWrapper}>
            <UnpicImage
              layout="fullWidth"
              css={styles.image}
              src={src!}
              alt={title!}
              background="auto"
            />
          </div>
        ))}
      </div>
    </ScrollContainer>
  );
};

export default CarouselVariant1;
